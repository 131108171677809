import React from 'react';
import styled from 'styled-components';
import Button from './Button.js';
import EqGrid from '../layout/Grid/EqGrid.js';
import OffsetGrid from './OffsetGrid.js';
import halfCircle from '../assets/half-circle--beige.svg';

const StyledCtaSection = styled.div`
  position: relative;
  z-index: 0;
  &:before {
    position: absolute;
    top: -60px;
    left: -180px;
    content: '';
    z-index: -1;
    width: 520px;
    height: 520px;
    border-radius: 50%;
    background-color: var(--moonlight);
    opacity: 0.2;
  }
`;

export default function CtaSection() {
  return (
    <StyledCtaSection>
      <h2>Unser Angebot</h2>
      {/* <OffsetGrid start="2" gap="0"> */}
      <h3>Die MOND-Phasen</h3>
      <p>
        Wir organisieren unsere Projekte mit den Partnern in einzelnen Phasen
        oder auch durchgehend. Als Mobilitätsberatung vor Ort arbeiten wir mit
        der AKKU-Methode:
        <br />
        <strong>Analyse, Konzeption, Kommunikation und Umsetzung.</strong>
      </p>

      <h3>Der Sinn der Raum-Fahrt </h3>
      <p>
        Wir verstehen uns als Raum-Fahrer – an der Schnittstelle zwischen
        Immobilien und Mobilität und zwischen Quartieren und Unternehmen, um
        richtig Fahrt aufzunehmen. Wir wollen luftigere, leisere und lässigere
        Städte durch flexiblere und intelligentere Mobilitätsangebote und
        -infrastrukturen von Arbeitgeber und Wohnungswirtschaft. Konkret: Wir
        wollen Ihre Reduktion von Komplexität, Kosten und CO2. Kurz: Wir wollen
        mondäne Mobilität.
      </p>
      <p>
        <strong>
          Sie wollen mehr über unser konkretes Beratungsangebot erfahren?
        </strong>
        <br />
        Schauen Sie sich unsere Beratungsmodule an.
      </p>
      {/* </OffsetGrid> */}
      <EqGrid md="1" centered={true}>
        <Button to="/angebot/" className="secondary mt-2">
          Zu unserem Angebot
        </Button>
      </EqGrid>
    </StyledCtaSection>
  );
}

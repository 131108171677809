import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';

const StyledArticleLinkWrapper = styled.a`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-bottom: none;

  > div {
    margin-top: auto;
    height: 100%;
  }

  p {
    margin-top: auto;
    text-align: center;
  }

  &:hover {
    .gatsby-image-wrapper {
      opacity: 0.8;
      /* filter: grayscale(0%); */
    }
  }

  .gatsby-image-wrapper {
    /* transition: 0.2s ease; */
    filter: grayscale(100%);
  }
`;

const StyledPressArticle = styled.div`
  max-width: 200px;
  h3 {
    /* color: red;
    border: 1px solid red; */
  }
`;

export default function PressArticle({ article }) {
  // function truncateString(str, num) {
  //   if (str.length <= num) {
  //     return str;
  //   }
  //   return str.slice(0, num) + '...';
  // }
  // const articleName = article.name;
  // const truncatedArticleName = truncateString(articleName, 50);

  return (
    <StyledPressArticle>
      <StyledArticleLinkWrapper
        href={article.link}
        target="_blank"
        rel="noreferrer"
        className="link--wrapper"
      >
        <Img
          fluid={article.logo.asset.fluid}
          durationFadeIn={400}
          alt=""
          style={
            {
              // height: "100%",
            }
          }
          imgStyle={{
            objectFit: 'contain',
            marginTop: 'auto',
          }}
        />
      </StyledArticleLinkWrapper>
    </StyledPressArticle>
  );
}

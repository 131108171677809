import React from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';
import PressArticle from './PressArticle';
import EqGrid from '../layout/Grid/EqGrid';

export default function PageBuilder({ elements }) {
  console.log(elements);
  // const page = data.page || data.route.page;

  const content = elements.pageBuilder.map((c, i) => {
    let el = null;
    switch (c._type) {
      case 'headlineAndCopy':
        el = (
          <>
            <h2 className="mt-6">{c.headline}</h2>
            <p>{c.copy}</p>
          </>
        );
        break;
      case 'pressArticleReference':
        el = (
          <PressArticle article={c.articleReference} />

          // <P>
          //   <h3>
          //     <a href={c.articleReference.link} target="_blank">
          //       {c.articleReference.name}
          //     </a>
          //   </h3>
          // </PressArticle>
        );
        break;
      default:
        el = null;
    }
    return el;
  });

  return <>{content}</>;
}

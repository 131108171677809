import React from "react";
import { above, below } from "../styles/MediaQueries";
import styled from "styled-components";

const OffsetGridStyled = styled.div`
  ${below.medium`
  // padding: 0 4rem;
  `}

  ${above.medium`
  display: grid;
  grid-gap: ${props => props.gap};
  grid-template-columns: repeat(12, 1fr);

  > * {
    grid-column: ${props => props.start} / span ${props => props.span};
    // background-color: blue;
  }

  `}

`;

export default function OffsetGrid({ children, start = "1", span = "12", gap = "2rem" }) {
    return (
      <OffsetGridStyled start={start} span={span} gap={gap}>
          {children}
      </OffsetGridStyled>
    )
  }
import React from 'react';
import { graphql } from 'gatsby';
import Hero from '../components/Hero';
import ProjectTeaser from '../components/ProjectTeaser/ProjectTeaser';
import Section from '../layout/Section';
import IntroSection from '../components/IntroSection';
import GridContainer from '../layout/Grid/GridContainer.js';
import GridRow from '../layout/Grid/GridRow';
import GridColumn from '../layout/Grid/GridColumn';
import Testimonial from '../components/Testimonial';
import CtaSection from '../components/CtaSection';
import SEO from '../components/SEO.js';
import PageBuilder from '../components/PageBuilder';
import EqGrid from '../layout/Grid/EqGrid';
import Button from '../components/Button';
import PressArticle from '../components/PressArticle';
import PressArticleWrapper from '../components/PressArticleWrapper';

export default function HomePage({ data }) {
  return (
    <>
      <SEO title={data.page.seoTitle} description={data.page.seoDescription} />

      <Hero hero={data.page} />

      <Section>
        <GridContainer>
          <GridRow>
            <GridColumn start="3" span="8">
              <IntroSection intro={data.page} />
            </GridColumn>
          </GridRow>
        </GridContainer>
      </Section>

      <Section className="pt-0">
        <GridContainer>
          <GridRow>
            <GridColumn start="3" span="8">
              <Testimonial source={data.testimonial} />
            </GridColumn>
          </GridRow>
        </GridContainer>
      </Section>

      <Section color="subtle">
        <GridContainer>
          <GridRow>
            <GridColumn start="3" span="9">
              <h2>Projekt-Auswahl</h2>
              <ProjectTeaser
                path="/projekte"
                showMoreButton="true"
                projects={data.projects.nodes}
                limit="3"
              ></ProjectTeaser>
            </GridColumn>
          </GridRow>
        </GridContainer>
      </Section>

      {/* <Section>
				<GridContainer>
					<GridRow>
						<GridColumn start="3" span="9">
							<h2>Magazin</h2>
							<MagazineTeaser magazineData={magazineData}></MagazineTeaser>
						</GridColumn>
					</GridRow>
				</GridContainer>
			</Section> */}

      {/* <Section>
				<GridContainer>
					<GridRow>
						<GridColumn start="1" span="2">
							Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sint corporis suscipit ab, ad eos laboriosam vero sapiente molestias, totam sed quas quos optio rem doloremque nisi. Iste sint nam nesciunt.
							</GridColumn>
						<GridColumn start="3" span="2">
							Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sint corporis suscipit ab, ad eos laboriosam vero sapiente molestias, totam sed quas quos optio rem doloremque nisi. Iste sint nam nesciunt.
							</GridColumn>
						<GridColumn start="5" span="2">
							Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sint corporis suscipit ab, ad eos laboriosam vero sapiente molestias, totam sed quas quos optio rem doloremque nisi. Iste sint nam nesciunt.
							</GridColumn>
						<GridColumn start="7" span="2">
							Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sint corporis suscipit ab, ad eos laboriosam vero sapiente molestias, totam sed quas quos optio rem doloremque nisi. Iste sint nam nesciunt.
							</GridColumn>
						<GridColumn start="9" span="2">
							Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sint corporis suscipit ab, ad eos laboriosam vero sapiente molestias, totam sed quas quos optio rem doloremque nisi. Iste sint nam nesciunt.
							</GridColumn>
						<GridColumn start="11" span="2">
							Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sint corporis suscipit ab, ad eos laboriosam vero sapiente molestias, totam sed quas quos optio rem doloremque nisi. Iste sint nam nesciunt.
							</GridColumn>
					</GridRow>
				</GridContainer>
			</Section> */}

      <Section color="subtle05">
        <GridContainer>
          <GridRow>
            <GridColumn start="3" span="9">
              <h3 className="mt-0">
                Sie wollen mehr über unser konkretes Beratungsangebot erfahren?
              </h3>
              <p>Schauen Sie sich unsere Beratungsmodule an.</p>
              <Button to="/angebot/" className="secondary">
                Zu unserem Angebot
              </Button>
            </GridColumn>
          </GridRow>
        </GridContainer>
      </Section>

      <Section>
        <GridContainer>
          <GridRow>
            <GridColumn start="3" span="8">
              <h2>Presse</h2>
              <p>
                Klicken Sie auf eines der Logos, um den Beitrag auf der Website
                des Mediums zu lesen.
              </p>
              <EqGrid xs="2" sm="2" md="5" className="mt-4">
                <PressArticleWrapper article={data.press.nodes}>
                  <PressArticle />
                </PressArticleWrapper>
              </EqGrid>
              {/* <EqGrid md="1" centered={true}>
                <Button to="/presse/" className="mt-2">
                  Weitere Artikel
                </Button>
              </EqGrid> */}
            </GridColumn>
          </GridRow>
        </GridContainer>
      </Section>
    </>
  );
}

// Cick outside
// TODO: finalize
// window.addEventListener("click", function (e) {
//   const isOutside = !e.target.closest("nav");
//   if (isOutside) {
//     document.body.classList.remove("is-open");
//   }
// });

export const query = graphql`
  query queryHome {
    page: sanityMainPages(slug: { current: { eq: "home" } }) {
      headline
      seoTitle
      seoDescription
      _rawIntroCopy
      heroHeadline
      enableHero
      heroImage {
        alt
        asset {
          fluid(maxWidth: 1920) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }

    testimonial: sanityTestimonial(
      _id: { eq: "a32b0b1f-c05a-4375-8677-6c135f74767a" }
    ) {
      name
      function
      quote
    }

    press: allSanityPressArticles(filter: { category: { ne: "fromUs" } }) {
      nodes {
        name
        link
        logo {
          asset {
            fluid(maxWidth: 600) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }

    projects: allSanityProject(
      limit: 3
      sort: { fields: [projectDate], order: DESC }
    ) {
      nodes {
        customerName
        projectDate
        id
        headline
        achievements
        slug {
          current
        }
        photos {
          photo {
            asset {
              fixed(width: 650, height: 650) {
                ...GatsbySanityImageFixed
              }
              fluid(maxWidth: 650, maxHeight: 650) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
  }
`;

import React from 'react';
import styled from 'styled-components';
import PressArticle from '../components/PressArticle';

const StyledPressArticle = styled.div`
  h3 {
    color: red;
    border: 1px solid red;
  }
`;

export default function PressArticleWrapper({ article }) {
  return (
    <>
      {article.map((a, i) => (
        <PressArticle article={a} />
      ))}
    </>
  );
}

import React from 'react';
import styled from 'styled-components';
import { above } from '../styles/MediaQueries';
import circle from '../assets/circle--moonlight-30.svg';

const testimonialData = {
  testimonial:
    'Die Flughafen Berlin-Brandenburg GmbH arbeitet im Rahmen ihrer Strategien zur Nachhaltigkeit und betrieblichen Gesundheit im Bereich der Fahrradmobilität mit dem Team von MOND zusammen. Die hohe Individualisierung, die Online-Portal-Lösungen sowie das Beratungsangebot auch für weitere Bereiche in unserem Hause haben uns bei MOND überzeugt.',
  source: 'Anika Cerni',
  role:
    'Geschäftsbereich Personal & Organisation | Flughafen Berlin Brandenburg',
};

const StyledTestimonial = styled.div`
  position: relative;
  z-index: 0;

  .testimonial {
    margin: 0;
    font-size: var(--font-l);
    font-family: var(--sans-serif);
    font-weight: 600;
    line-height: 1.2;
    z-index: 1;
    padding: 6rem 0 6rem 2rem;
    ${above.medium`
        padding: 6rem 0 6rem 8rem;
      `}
    position: relative;
    &:before {
      content: '»';
      font-family: var(--sans-serif);
      font-weight: 700;
      position: absolute;
      z-index: -2;
      color: var(--moonlight);
      top: -7.5rem;
      left: -1rem;
      font-size: 20rem;
      ${above.medium`
      	top: -6rem;
        left: 0;
      `}
    }

    &__footer {
      padding: 0;
      font-size: var(--font-base);
      background-color: transparent;
      margin-top: 1rem;
    }

    &__source {
      font-family: var(--sans-serif);
      text-transform: uppercase;
      display: inline-block;
      margin-top: 0.5rem;
    }

    &__role {
      display: inline-block;
      margin-top: 0.25rem;
      font-family: var(--serif);
      font-weight: normal;
    }
  }

  // circle decor
  img {
    position: absolute;
    top: -6rem;
    left: -6rem;
    ${above.medium`
    	top: 0;
      left: 2rem;
      `}
    fill: blue;
    z-index: -1;
    pointer-events: none;
    height: 100%;
    opacity: 0.66;
  }
`;

export default function Testimonial({ source }) {
  return (
    <StyledTestimonial>
      <blockquote className="testimonial">
        »{source.quote}«
        <footer className="testimonial__footer">
          <span className="testimonial__source">{source.name}</span>
          <br />
          <span className="testimonial__role">{source.function}</span>
        </footer>
      </blockquote>
      <img src={circle} alt="" />
    </StyledTestimonial>
  );
}
